.yacheyka-dlya-protseduri {
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
    .dg__signature {
      width: 55%;
    }
    .ds__use-signature {
      width: 15%;
    }
    .signature {
      width: 25%;
    }
    .additional-container__date {
      width: fit-content;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .tox-tinymce {
    height: 730px !important;
  }
}
