.zayavka-na-strahovanie-sredstv {
  .head-text {
    width: 50%;
    margin: 0 auto 0 auto;
  }
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .rdw-editor-main {
    height: 30vh;
    overflow-y: auto;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
    .dg__signature {
      width: 55%;
    }
    .ds__use-signature {
      width: 15%;
    }
    .signature {
      width: 25%;
    }
    .additional-container__date {
      width: fit-content;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
}
