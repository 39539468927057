.status {
  align-items: center;
  margin-bottom: 0.5rem;
  .btn {
    width: 220px;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .status-separator {
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .status-old-name {
    height: 100%;
  }
}
