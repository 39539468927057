.import-db-form {
	.import-db-form-heading {
		text-align: center;
	}
	form {
		label {
			display: block;
			margin-bottom: 1.5rem;
		}
	}
}
