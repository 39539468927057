.pagination-block {
  gap: 5px;
  .page-item.active {
    .page-link {
      background-color: #3e4b63;
      border-color: #636567;
      color: #69a0f1;
    }
  }
  .page-link {
    padding: 0.375rem 0.45rem;
    background-color: #343f53;
    border-color: #636567;
    color: #8eb1e5;
  }
}
