.tamognya-plategnoe-poruchenie {
  .head-container {
    margin-bottom: 15px;
  }
  .head-text {
    width: 50%;
    max-height: 75px;
    margin-left: auto;
  }
  .title {
    width: 50%;
    margin: 0 auto;
  }
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .tc-cell {
    border: 0.5px dotted var(--color-border);
  }
  .tc-row {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      border-bottom: 1px solid black;
    }
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
    .dg__signature {
      width: 55%;
    }
    .ds__use-signature {
      width: 15%;
    }
    .signature {
      width: 25%;
    }
    .additional-container__date {
      width: fit-content;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .tox-tinymce {
    height: 680px !important;
  }
}
