.entity-identifier {
	display: flex;
	align-items: center;
	margin-right: 0.5rem;
}
.right-answer-selector {
	display: flex;
	border-radius: 4px;
	overflow: hidden;
	.right-answer-selector-label {
		font-size: .8rem;
		white-space: nowrap;
		display: flex;
		align-items: center;
		background-color: lighten($card-bg-color, 6);
		padding-left: 4px;
		padding-right: 4px;
	}
	select {
		background-color: $page-bg-color;
		color: $text-color;
		border: 0;
		border-radius: 0;
	}
}
