.data-base-name {
	span {
		margin-left: 4px;
		color: $success-color;
		font-weight: 600;
	}
}
.download-statuses-select{
	height: 300px;
}
.imported-data {
	th, td {
		min-width: 100px;
	}
}