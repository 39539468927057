.active-databases-container {
  .active-databases-header {
    justify-content: space-between;
    .active-databases-actions {
      .active-databases-info-container {
        align-items: center;
        margin-right: 1rem;
        .active-databases-info {
          padding: 2px 4px;
          border-radius: 1000px;
          background-color: $info-color;
          font-size: 0.6rem;
        }
      }
    }
  }
  .overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(52, 63, 83, 0.7);
  }
  .loader-spinner-container {
    position: absolute;
  }
}
