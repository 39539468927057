.fsb-prikaz {
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .editor-h-container {
    column-gap: 15px;
    .tox-tinymce {
      height: 330px !important;
    }
  }
  .body-container {
    .tox-tinymce {
      height: 600px !important;
    }
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
    .dg__signature {
      width: 55%;
    }
    .ds__use-signature {
      width: 15%;
    }
    .dg__signature-owner {
      width: 25%;
    }
    .signature {
      width: 20%;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .header,
  .client {
    width: calc((100% / 2) - (15px / 2));
  }
  .additional-container__date {
    width: fit-content;
  }
}
