.small-notion {
	font-size: 10px;
	border-radius: 9999px;
	white-space: nowrap;
	padding: 4px;
	margin-right: 1rem;
	height: fit-content;
	&.success {
		background-color: $success-color;
	}
	&.disabled {
		background-color: $disabled-color;
	}
	&.warning {
		background-color: $warning-color;
	}
	&.danger {
		background-color: $danger-color;
	}
	&.info {
		background-color: $info-color;
	}
}
