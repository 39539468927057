.profile-card{
    .card-body.card-img-top{
        background-image: url("../../assets/images/panel_bg.png");
    }
    img{
        width: 170px;
        height: 170px;
    }
    .card-img-actions{
        position: relative;
    }
    .card-img-actions-overlay{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.75);
        color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out .15s;
    }
    .my-profile-label{
        position: relative;
        width: 100%;
        &::before{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #fff;
            opacity: 0;
            visibility: hidden;
            transition: .3s;
        }
        &:hover{
            &::before{
                opacity: .1;
                visibility: visible;

            }
        }
        .material-icons{
            margin-right: 1rem;
        }

    }
}
.card-img-actions:hover .card-img-actions-overlay {
    opacity: 1;
    visibility: visible;
}