.usloviya-bankovskogo-obslugivaniya {
  .additional-container {
    width: 50%;
    column-gap: 15px;
    .signature {
      width: 50%;
    }
  }
  .label {
    text-wrap: nowrap;
  }
  h6 {
    margin-bottom: 5px;
  }
  .tox-tinymce {
    height: 650px !important;
  }
}
