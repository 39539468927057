.nbk-seyfovaya-yacheyka {
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .header-container {
    .form-control {
      width: 50%;
      margin: 0 auto;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .position-container {
    margin-bottom: 1rem;
  }
  .position {
    width: 45%;
    max-height: 75px;
  }
  .additional-container {
    .signature {
      width: 25%;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
}
