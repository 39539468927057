.notification-container {
  position: relative;
  .notification-button {
    position: relative;
    background-color: transparent;
    padding: 0;
    color: $dark-text-color;
    pointer-events: none;
    .icon {
      font-size: 30px;
    }
    &:not([disabled]) {
      pointer-events: painted;
      @include blinker;
    }
  }
  .counter-container {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: red;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    color: $text-color;
    font-size: 8.5px;
  }
  .notifications-container {
    position: absolute;
    top: 2rem;
    z-index: 10;
    transform: translate(-80%, 0);
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    background-color: $card-bg-color;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    transition: visibility 0s, opacity 0.25s linear;
    &.hiden {
      visibility: hidden;
      opacity: 0;
    }
    &.shown {
      visibility: visible;
      opacity: 1;
    }
    .notification {
      width: 350px;
    }
  }
  .notification {
    display: flex;
    border: 1px solid #e7e7e0;
    border-radius: 5px;
  }
  .close-button {
    padding: 0;
    background-color: transparent;
    height: fit-content;
  }

  .notifications-modal {
    padding: 10px;
    background-color: $card-bg-color;
    max-height: 90dvh;
    overflow-y: auto;
    .notification {
      flex-direction: column;
      gap: 8px;
    }
  }
  .agree-button-wrapper {
    display: flex;
    justify-content: center;
  }
}
