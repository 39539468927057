.client-main-container {
  display: flex;
  padding: 0;
  margin: 0;
}
.client-main-info-container {
  padding: 0;
  margin: 0;
}
.sidebar {
  .sidebar-heading {
    color: $info-color;
  }
  .scripts-container {
    overflow-y: auto;
    max-height: 50vh;
  }
  .recalls-container {
    overflow-y: auto;
    max-height: 66vh;
    .sub-text {
      color: $dark-text-color;
    }
    .card {
      overflow-y: auto;
      overflow-x: hidden;
      &__button-container {
        margin-top: 5px;
      }
      button {
        padding: 0rem 0.25rem 0 0.25rem;
        font-size: 12px;
      }
      &:hover {
        background-color: #464f5f;
        cursor: pointer;
      }
    }
  }
  .transfers-container {
    overflow-y: auto;
    max-height: 66vh;
    .sub-text {
      color: $dark-text-color;
    }
    .card {
      overflow-y: auto;
      overflow-x: hidden;
      &__button-container {
        margin-top: 5px;
      }
      button {
        padding: 0rem 0.25rem 0 0.25rem;
        font-size: 12px;
      }
    }
  }

  .general-scripts-block {
    margin-bottom: 1rem;
  }
  .state {
    padding: 2px 10px;
    border-radius: 1000px;
    font-size: 0.6rem;
    width: fit-content;
    &.accepted {
      background-color: $success-color;
    }
    &.rejected {
      background-color: $danger-color;
    }
  }
}
.client-main-info-container {
  display: flex;
}
.sidebar-heading.recalls-heading {
  font-size: 1rem;
}
.script-canvas {
  background-color: #fff;
  color: black;
  border-radius: 8px;
}

.remove-recall {
  color: var(--bs-danger);
  cursor: pointer;
}

.user-action-container {
  align-items: center;
  column-gap: 1em;
  padding-left: calc(100% / 6);
}

.timezone-data-container {
  padding-left: calc(100% / 6);
}
.timezone-data {
  width: fit-content;
  color: #9298a8;
  background-color: #262d3d;
  background-color: #1c212d;
  border-radius: 5px;
  font-size: 12px;
  p {
    margin-top: 0.5em;
  }
}

.user-information-form {
  .btn-info {
    color: #fff;
  }
  .bank-links {
    margin-top: unset !important;
  }
  .disabled {
    opacity: 0.4;
    cursor: default !important;
    pointer-events: none;
  }
}
.empty-list-wrapper {
  margin: 5vh 0;
}

.mobile-only {
  display: none !important;
}
.messenger-btn {
  img {
    width: 20px;
  }
}

.call-btn,
.sip-btn {
  img {
    border: 2px solid #ffffff;
    border-radius: 50%;
  }
}

.mobile-save-button-wrapper {
  display: none;
}

@media (max-width: calc(1024px - 1px)) {
  .empty-list-container {
    margin: 0 auto;
  }
  .messenger-btn,
  .call-btn {
    gap: unset !important;
    padding: 0;
    height: fit-content;
    font-size: 0;
    background: unset;
    border: unset;
    img {
      width: 30px;
    }

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
      background: unset;
      box-shadow: none;
    }
    &.disabled {
      background: unset;
      box-shadow: none;
    }
  }
}

@media (max-width: calc(768px - 1px)) {
  .client-main-container {
    flex-direction: column-reverse;
  }
  .client-main-info-container {
    width: 100%;
  }
  .sidebar {
    width: 100%;
    margin-top: 1rem;
  }

  .client-main-info {
    width: 100%;
  }
  .bottom-actions-container {
    display: none !important;
  }
  .client-additional-info {
    width: 100%;
  }

  .user-action-container {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  .call-btn,
  .mobile-save-button-wrapper,
  .mobile-only {
    display: block !important;
  }
  .my-scripts-block,
  .general-scripts-block {
    display: none;
  }
}
