.client-filters {
  font-size: 0.8rem;
  .add-new-filter-button {
    font-size: 0.9rem;
    color: $info-color;
  }
  .add-new-filter-button,
  .filter-controls > * {
    cursor: pointer;
  }
  .active-filters {
    .active-filter {
      margin-bottom: 1rem;
      overflow-y: auto;
      // padding: 10px;
      border: 1px solid $text-color;
      border-radius: 5px;
    }
    .filter-controls {
      .edit-filter {
        font-size: 0.7rem;
        color: $success-color;
      }
      .remove-filter {
        color: $danger-color;
      }
    }
    .filter-value {
      .filter-name {
        color: $info-color;
      }
    }
    .filter-clients-found {
      width: fit-content;
      font-size: 0.6rem;
      background-color: $info-color;
      border-radius: 1000px; // Max as possible in pixels
      padding: 2px 8px;
    }
  }
  .form-control {
    padding: 3px 6px;
  }
  .select-all-btn {
    padding: 0.2rem 0.4rem;
    font-size: 0.65rem;
    width: fit-content;
  }
}
