.text-variables{
    border-radius: 8px;
    background-color: white;
    color: $dark-text-color;
    p{
        font-weight: 600;
    }
}
.rdw-editor-wrapper{
    border-radius: 8px;
    background-color: white;
    color: black;
    height: 100%;
    .rdw-editor-main{
        height: auto;
        padding-inline: 1rem;
    }
}