.poruchenie-vladeltsa-scheta {
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
    .dg__signature {
      width: auto;
    }
    .additional-container__date {
      width: unset;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .tox-tinymce {
    height: 650px !important;
  }
}
