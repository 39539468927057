.sorting-table {
  margin-top: 1rem;
  table {
    margin-bottom: 0;
    .form-check {
      width: fit-content;
    }
    .header-check-box {
      border: 3px solid;
    }
    th,
    td {
      vertical-align: top;
      border-left: 0;
      border-right: 0;
      color: $text-color;
    }
    tr:hover > * {
      color: darken($text-color, 6) !important;
    }
    .react-bs-table-no-data {
      text-align: center;
    }
  }
  .react-bs-table-search-form {
    padding-bottom: 1rem;
  }
  .pagination {
    float: right;
    .page-item {
      .page-link {
        background-color: $card-bg-color;
        border-color: $grey-color;
        color: #8eb1e5;
      }
      &.active {
        .page-link {
          background-color: lighten($card-bg-color, 5);
          border-color: $grey-color;
          color: #69a0f1;
        }
      }
    }
  }
  .dropdown-menu {
    min-width: unset;
  }
}
