.edinaya-forma-soglasiya-container {
  .header-container {
    .header-wrapper {
      width: 50%;
    }
    input {
      width: 75%;
    }
  }
  .rdw-editor-main {
    height: 15vh;
    overflow-y: auto;
  }
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
  }
  h6 {
    margin-bottom: 5px;
  }
  .bottom-content {
    text-align: center;
  }
  .tox-tinymce {
    height: 250px !important;
  }
}
