.declaraciay-container {
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .editor-h-container {
    column-gap: 15px;
    .card {
      height: unset;
    }
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
  }
  h6 {
    margin-bottom: 5px;
  }
  .header,
  .client {
    width: calc((100% / 2) - (15px / 2));
  }
  .position-container {
    display: flex;
    flex-direction: column;
    width: calc((100% / 2) - (15px / 2));
    row-gap: 10px;
  }
  .dg__signature {
    width: calc((100% / 2) - (15px / 2));
  }
  .ds__termin-container {
    gap: 1rem;
    width: fit-content;
  }
  .body {
    .tox-tinymce {
      height: 350px !important;
    }
  }
}
