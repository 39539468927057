.centro-zayavlenie-v2 {
  .additional-container {
    width: 50%;
    column-gap: 15px;
    .ds__use-signature {
      width: 15%;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .tox-tinymce {
    height: 700px !important;
  }
}
