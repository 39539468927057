.settings-heading {
  margin-bottom: 1rem;
  .settings-info {
    font-size: 0.8rem;
    .settings-info-heading {
      font-weight: 600;
    }
  }
  .settings-controls {
    margin: 0 -6px;
    .button-wrapper {
      padding: 0 6px;
    }
  }
}
