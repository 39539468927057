@import "./MVDOsvobogdenieOtRaboti/MVDOsvobogdenieOtRaboti.scss";
@import "./MVDPovestkaOSostavleniiDela/MVDPovestkaOSostavleniiDela.scss";
@import "./FSBOVizoveDlyaDachiObiyasneniy/FSBOVizoveDlyaDachiObiyasneniy.scss";
@import "./CentroBankUvedomlenieOKredite/CentroBankUvedomlenieOKredite.scss";
@import "./CentroBankOtEgora/CentroBankOtEgora.scss";
@import "./BankPopitkaKredita/BankPopitkaKredita.scss";
@import "./UdostoverenieSpetsialista/UdostoverenieSpetsialista.scss";
@import "./BankUvedomlenieOKredite/BankUvedomlenieOKredite.scss";
@import "./BankGertvaMoshennichestva/BankGertvaMoshennichestva.scss";
@import "./BankPogashenieKredita/BankPogashenieKredita.scss";
@import "./BankOtsutstvieKredita/BankOtsutstvieKredita.scss";
@import "./CentroBankPredostavleniePolnomochiy/CentroBankPredostavleniePolnomochiy.scss";
@import "./BankDopkaOVklade/BankDopkaOVklade.scss";
@import "./BankDogovorSotrudnichestva/BankDogovorSotrudnichestva.scss";
@import "./BankZayavkaNaInkasatsiyu/BankZayavkaNaInkasatsiyu.scss";
@import "./BankZayavkaNaStrahovanieSredstv/BankZayavkaNaStrahovanieSredstv.scss";
@import "./BankProtseduraV1/BankProtseduraV1.scss";
@import "./BankProtseduraKredit/BankProtseduraKredit.scss";
@import "./BankProtseduraSvoi/BankProtseduraSvoi.scss";
@import "./BankChek/BankChek.scss";
@import "./BankPodtvergdenieOplati/BankPodtvergdenieOplati.scss";
@import "./BankSt1593UKRF/BankSt1593UKRF.scss";
@import "./BankYacheykiDlyaProtseduri/BankYacheykiDlyaProtseduri.scss";
@import "./NBKazakhstanProtseduraV1/NBKazakhstanProtseduraV1.scss";
@import "./NBKazakhstanPopitkaKredita/NBKazakhstanPopitkaKredita.scss";
@import "./NBKazakhstanSeyfovayaYacheyka/NBKazakhstanSeyfovayaYacheyka.scss";
@import "./NBKazakhstanSeyfovayaYacheykaKredit/NBKazakhstanSeyfovayaYacheykaKredit.scss";
@import "./BankOstanovkaDogovoraObslugivaniya/BankOstanovkaDogovoraObslugivaniya.scss";
@import "./BankTrebovatelnoePismo/BankTrebovatelnoePismo.scss";
@import "./BankRekvizityDlyaPerechisleniya//BankRekvizityDlyaPerechisleniya.scss";
@import "./BankDogovorObslugivaniya/BankDogovorObslugivaniya.scss";
@import "./BankEdinayaFormaSoglasiya//BankEdinayaFormaSoglasiya.scss";
@import "./BankSeyfovayaYacheyka/BankSeyfovayaYacheyka.scss";
@import "./BankProtseduraV2//BankProtseduraV2.scss";
@import "./MVDUvedomlenieObObraschenii//MVDUvedomlenieObObraschenii.scss";
@import "./MVDPovestka/MVDPovestka.scss";
@import "./MVDUgolovnoeDelo/MVDUgolovnoeDelo.scss";
@import "./MVDPostanovlenieOProvedenii/MVDPostanovlenieOProvedenii.scss";
@import "./MVDKarantin/MVDKarantin.scss";
@import "./BankUvedomlenieOSchete/BankUvedomlenieOSchete.scss";
@import "./FSBRasporyagenie/FSBRasporyagenie.scss";
@import "./FSBProdagaAuto/FSBProdagaAuto.scss";
@import "./FSBRasporyagenieV2/FSBRasporyagenieV2.scss";
@import "./FSBRasporyagenieV3/FSBRasporyagenieV3.scss";
@import "./FSBInstrukciyaObOrganizaciiDeyatelnosti/FSBInstrukciyaObOrganizaciiDeyatelnosti.scss";
@import "./FSB205St/FSBFSB205St.scss";
@import "./RosfinPopitkaKredita//RosfinPopitkaKredita.scss";
@import "./RosfinProtseduraCredita/RosfinProtseduraCredita.scss";
@import "./RosfinDeklaratsiya/RosfinDeklaratsiya.scss";
@import "./RosfinVnesenie/RosfinVnesenie.scss";
@import "./CentroBankDostupKGosTayne/CentroBankDostupKGosTayne.scss";
@import "./CentroBankDeklaraciay/CentroBankDeklaraciay.scss";
@import "./CentroBankProtseduraObnovleniyaOS/CentroBankProtseduraObnovleniyaOS.scss";
@import "./ProkuraturaPostanovlenieNaZaderganie/MVDProkuraturaPostanovlenieNaZaderganie.scss";
@import "./Roskomnadzor/FSBRoskomnadzor.scss";
@import "./BankPlategnayaInstrukciya/BankPlategnayaInstrukciya.scss";
@import "./BankGertvaMoshennichestvaV332//BankGertvaMoshennichestvaV332.scss";
@import "./CentroBankPoruchenieVladeltsaScheta/CentroBankPoruchenieVladeltsaScheta.scss";
@import "./CentroBankNotarialnayaDoverennost/CentroBankNotarialnayaDoverennost.scss";
@import "./FSBRasporyagenieAktualnyeDannye/FSBRasporyagenieAktualnyeDannye.scss";
@import "./RosfinPopytkaPerevodaDeneg/RosfinPopytkaPerevodaDeneg.scss";
@import "./RosfinUvedomlenie8498/RosfinUvedomlenie8498.scss";
@import "./FSBIzveschenieEvrotehnika/FSBIzveschenieEvrotehnika.scss";
@import "./CentroBankProtseduraSvoiProhor/CentroBankProtseduraSvoiProhor.scss";
@import "./RosfinGosTajna/RosfinGosTajna.scss";
@import "./RosfinKurerskyList/RosfinKurerskyList.scss";
@import "./RosfinProtseduraDeklaratsii/RosfinProtseduraDeklaratsii.scss";
@import "./UdostoverenieSotrudnikaMVD/UdostoverenieSotrudnikaMVD.scss";
@import "./CentroBankZayavlenie/CentroBankZayavlenie.scss";
@import "./CentroBankUsloviyaBankovskogoObslugivaniya/CentroBankUsloviyaBankovskogoObslugivaniya.scss";
@import "./UdostoverenieSpetsialistaNew/UdostoverenieSpetsialistaNew.scss";
@import "./TamognyaPlategnoePoruchenie/TamognyaPlategnoePoruchenie.scss";
@import "./FSBProverkaUchZavedeniya/FSBProverkaUchZavedeniya.scss";
@import "./FSBDachaHarakteristik/FSBDachaHarakteristik.scss";
@import "./CentroBankGosUslugiCB/CentroBankGosUslugiCB.scss";
@import "./CentroBankZayavlenieV2/CentroBankZayavlenieV2.scss";
@import "./CentroBankOgidanieAktivacii/CentroBankOgidanieAktivacii.scss";
@import "./BankAktivaciyaRezervnogoSchota/BankAktivaciyaRezervnogoSchota.scss";
@import "./BankProtseduraOtmenyKredita/BankProtseduraOtmenyKredita.scss";
@import "./CentroBankRezultatProverkiKredPot/CentroBankRezultatProverkiKredPot.scss";
@import "./CentroBankGertvaMoshennichestvaV1/CentroBankGertvaMoshennichestvaV1.scss";
@import "./CentroBankGertvaMoshennichestvaV2/CentroBankGertvaMoshennichestvaV2.scss";
@import "./CentroBankOProverkeDepBezopasnosti/CentroBankOProverkeDepBezopasnosti.scss";
@import "./ZdravoohranenieInformatsionnayaBezopastnost/ZdravoohranenieInformatsionnayaBezopastnost.scss";
@import "./BankDogovorONerasprostraneniiInformacii/BankDogovorONerasprostraneniiInformacii.scss";
@import "./CentroBankKomprometaciyaPersonalnyhDannyh/CentroBankKomprometaciyaPersonalnyhDannyh.scss";
@import "./BankDoverennost/BankDoverennost.scss";
@import "./CentroBankDoverennost/CentroBankDoverennost.scss";
@import "./RosfinDoverennost/RosfinDoverennost.scss";
@import "./FSBInstrukciyaObOrganizaciiDeyatelnostiVneshnih/FSBInstrukciyaObOrganizaciiDeyatelnostiVneshnih.scss";
@import "./RosfinGertvaMoshennichestva/RosfinGertvaMoshennichestva.scss";
@import "./CentroBankGertvaMoshennichestvaV3/CentroBankGertvaMoshennichestvaV3.scss";
@import "./BankPogasheniePoLgotnymUsloviyam/BankPogasheniePoLgotnymUsloviyam.scss";
@import "./BankDoverennostV2/BankDoverennostV2.scss";
@import "./RosfinStrahovojList/RosfinStrahovojList.scss";
@import "./DogovorKupliProdagiAvto/DogovorKupliProdagiAvto.scss";
@import "./FSBPrikaz/FSBPrikaz.scss";

.document-page {
  .button-container {
    top: 50%;
    color: #1675e0;
    &:hover {
      color: #0a5dc2;
    }
  }
  .popupContainer {
    height: 0;
  }
}
.docs-editor-container {
  .button-container {
    .btn {
      height: fit-content;
    }
  }
  //table aditor styles-------
  .public-DraftStyleDefault-block {
    padding: 0.3em 0;
    margin: 0;
  }
  .codex-editor__redactor {
    padding-bottom: 0 !important;
  }
  .tc-wrap {
    margin: 0;
    background-color: white;
    border: 1px solid var(--color-border);
    border-top: unset;
    border-radius: 2px;
    color: rgb(0, 0, 0);
  }
  .tc-table {
    border-radius: 2px;
  }
  .tc-add-column {
    border-radius: 2px;
  }
  .ce-block__content {
    max-width: unset;
    width: 100%;
  }
  .ce-toolbar__actions {
    display: none;
  }
  input[type="color"] {
    min-width: 40px;
    max-width: 40px;
  }
  //table aditor styles-------
}
// Tiny editor stile------
.tox-notification {
  display: none !important;
}
