header {
  margin-bottom: 2rem;
  .search-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .profile {
    .profile-image-wrapper {
      margin-right: 0.6rem;
      width: 36px;
      height: 36px;
    }
  }
  .home-page-link-container {
    border-bottom: 2px solid transparent;
    margin-right: 1rem;
    transition: border-bottom-color 0.3s;
  }
  .home-page-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #e7e7e0;
    align-items: center;
    display: flex;
    .material-icons-outlined {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
  .navbar {
    padding-bottom: 0;
    .nav-item {
      border-bottom: 2px solid transparent;
      transition: border-bottom-color 0.3s;
      // @QUESTION what margin-right here?
      margin-right: 1rem;
      &:hover,
      &:focus {
        border-bottom-color: $accent-color;
      }
    }
    .nav-link {
      color: $text-color;
      &:hover,
      &:focus {
        color: darken($text-color, 6);
      }
    }
    .dropdown-menu {
      background-color: $card-bg-color;
      .dropdown-item {
        color: $text-color;
        &:hover,
        &:focus {
          color: darken($text-color, 3);
          background-color: darken($card-bg-color, 3);
        }
      }
      &[data-bs-popper] {
        top: calc(100% + 8px);
        right: -30%;
        left: auto;
        margin-top: 0.125rem;
      }
    }
    .collapse:not(.show) {
      display: flex;
    }
    .dropdown-menu {
      position: absolute;
    }
  }
}
@media (max-width: calc(768px - 1px)) {
  header {
    .dropdown-item-wrapper,
    .dropdown-divider-wrapper {
      display: none;
    }
  }
}
