.postanovlenie-o-provedenii-container {
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .editor-h-container {
    column-gap: 15px;
    .card {
      height: unset;
    }
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
    .dg__signature {
      width: calc(50% - 15px / 2);
    }
    .ds__use-signature {
      width: 12%;
    }
    .signature {
      width: 20%;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .header {
    .tox-tinymce {
      height: 35vh !important;
    }
  }
  .body {
    .tox-tinymce {
      height: 60vh !important;
    }
  }

  .additional-container__date {
    width: fit-content;
  }
}
