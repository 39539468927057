.rosfin-protsedura-deklaratsii {
  .head-container {
    column-gap: 15px;
    .reg-data-container,
    .header {
      width: calc((100% / 2) - (15px / 2));
      height: auto;
    }
    .reg-additional-data-container {
      height: 100%;
    }
  }
  .head-text {
    width: 50%;
    max-height: 75px;
    margin-left: auto;
  }
  .reg-number {
    width: 50%;
  }
  .reg-number-container {
    margin-bottom: 1rem;
    width: 100%;
  }
  .reg-date-container {
    .form-control {
      width: 32%;
    }
  }
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .stamp-data-container {
    height: fit-content;
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
    .dg__signature {
      width: 55%;
    }
    .ds__use-signature {
      width: 15%;
    }
    .signature {
      width: 25%;
    }
    .additional-container__date {
      width: fit-content;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .ds__termin-container {
    gap: 1rem;
  }
  .body-container {
    .tox-tinymce {
      height: 500px !important;
    }
  }
}
