body.crm-view {
  background-color: $page-bg-color;
  color: $text-color;
  .card {
    background-color: $card-bg-color;
    margin-bottom: 1rem;
    height: calc(100% - 1rem);
    .card-header {
      background-color: transparent;
      border: 0;
    }
  }
  .form-control {
    color: $dark-text-color;
    background-color: $page-bg-color;
    border: 0;
    border-radius: 0;
    &:focus {
      color: $text-color;
      background-color: lighten($page-bg-color, 4);
      border-color: #172130;
    }
    &:disabled {
      background-color: darken($page-bg-color, 2);
      border-color: #172130;
    }
  }
  .form-group {
    margin-bottom: 1.25rem;
  }
  a.dropdown-item,
  .nav-link,
  .btn {
    display: flex;
    align-items: center;
    .material-icons,
    .material-icons-outlined {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
  label,
  .label-container {
    display: inline-block;
    margin-bottom: 0.5rem;
    i {
      color: $danger-color;
    }
  }
  .label-container label {
    margin-bottom: 0;
  }
  .duplicates-actions {
    button {
      margin: 0 4px;
    }
  }
  .show-called-dbs {
    border: 1px solid $info-color;
    color: $text-color;
    &:hover {
      color: darken($text-color, 8);
    }
  }
  .bordered {
    border: 1px solid rgba($color: #c4c4c4, $alpha: 0.5);
    border-radius: 3px;
  }
  .material-icons {
    font-size: $material-icons-font-size;
  }
  .form-check {
    .form-check-input {
      background-color: $card-bg-color;
      border-color: $text-color;
    }
  }
  .label {
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .label-alert {
    color: $danger-color;
  }
  .form-select {
    &:disabled {
      background-color: darken($page-bg-color, 2);
    }
  }

  select[multiple] {
    background-color: $page-bg-color;
    color: $text-color;
    font-size: 10px;
    optgroup {
      font-weight: bold;
    }
    option {
      padding: 4px 16px;
      border-radius: 4px;
      margin-bottom: 4px;
    }
  }

  .user-information-form {
    font-size: 12px;
    input[type="text"].form-control,
    textarea.form-control {
      background-color: darken($page-bg-color, 5);
      border-radius: 5px;
      font-size: 12px;
      &:not(.note-field) {
        resize: none;
      }
      &:focus {
        box-shadow: 0 0 0 0.05rem rgb(6, 62, 146);
      }
    }
    .textarea.form-control {
      height: 100%;
    }
  }

  .bank-links {
    display: inline-block;
    margin-top: 2rem;
    span {
      margin: 0 8px;
    }
  }
  .action-edit,
  .action-remove {
    cursor: pointer;
    &:first-child {
      margin-right: 1.5rem;
    }
  }
  .action-edit {
    color: $info-color;
  }
  .action-remove {
    color: $danger-color;
  }
  .script-item,
  .test-item {
    border-bottom: 1px solid gray;
    a {
      color: $text-color;
      font-weight: 600;
      text-decoration: none;
      transition: 0.3s;
      &:hover {
        color: darken($text-color, 7);
      }
    }
  }
  .pass-test-heading {
    .test-name-container {
      font-size: 1.2rem;
      .test-name {
        color: $success-color;
        font-weight: 600;
      }
    }

    .test-warning {
      color: $danger-color;
    }
  }
  .question-text {
    font-size: 1.4rem;
  }
  .wide-answer-block {
    .wide-answer-label {
      font-size: 1rem;
    }
    .wide-answer-textarea {
      textarea {
        width: 100%;
        color: $dark-text-color;
        padding: 1rem;
        &:disabled {
          color: black;
        }
      }
    }
  }
  .no-data-message {
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
  }
  .find-duplicates-container {
    .nav-link {
      color: #e7e7e0;
      &.active {
        color: #495057;
      }
    }
    .sorting-table {
      margin-top: unset;
    }
  }
}
body.reporting {
  .pivot-table {
    text-align: end;
    font-size: 1rem;
  }
  #root[data-bs-theme="dark"] {
    background-color: $dark--page-bg-color;
    color: $dark--text-color;
    scrollbar-color: $dark-text-color $dark--border-color;
    .card {
      background-color: $dark--card-bg-color;
    }
    .form-control {
      background-color: $dark--card-bg-color;
      border: 1px solid $dark--border-color;
      color: inherit;
    }
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      background: none;
      color: inherit;
      border-color: $dark--border-color;
    }
    .nav-tabs {
      border-bottom: 1px solid $dark--border-color;
    }
    .form-select {
      border: 1px solid $dark--border-color;
      @include base-style-dark;
    }
    .dropdown {
      ul {
        @include base-style-dark;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5), 0 6px 20px rgba(0, 0, 0, 0.5);
      }
    }
    .select-custom-container {
      @include base-style-dark;
      #react-select-2-placeholder {
        color: #6c757d;
      }
      .select-custom-prefix__control {
        border: 1px solid $dark--border-color;
        @include base-style-dark;
      }

      .select-custom-prefix__menu {
        @include base-style-dark;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5), 0 6px 20px rgba(0, 0, 0, 0.5);
      }
      .select-custom-prefix__option--is-focused {
        color: black;
      }
    }
    table {
      border: 1px solid $dark--border-color;
      @include base-style-dark;
      .additional-row {
        background-color: $dark--card-secondary-bg-color;
      }
      --bs-table-hover-color: $dark--text-color;
    }
    .table-success {
      --bs-table-bg: #cacaca;
      --bs-table-hover-bg: rgba(133, 131, 131, 0.2);
    }
  }
}
a,
a:hover {
  text-decoration: none;
}
.link-as-button {
  width: fit-content;
}
.user-info-page {
  .user-action-container,
  .timezone-data-container {
    padding-left: 0;
  }
  .phone-data-wrapper.col-6 {
    display: flex;
    width: 51%;
    .phone-data-container {
      padding-left: 4px;
    }
  }
  .backdrop-wrapper {
    position: relative;
  }
}
.tablet-container.with-sorting {
  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .dropup .caret,
  .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
  }
}
// To hide Upgrate button in tiny text editor
.tox-promotion {
  display: none;
}
