body.crm-view{
	.popupContainer {
		button.open-modal {
			background-color: transparent;
			border: 0;
			padding: 0;
		}
		.modal-dialog {
			max-width: 792px;
			.modal-content {
				background-color: $card-bg-color;
				.modal-footer {
					border-top: 0;
				}
			}
		}
	}

}
