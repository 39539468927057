.nbk-protsedura-v1-container {
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .rdw-editor-main {
    max-height: 50vh;
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
  }
  .bottom-container {
    column-gap: 15px;
    textarea {
      width: 40%;
    }
    .signature-container {
      display: flex;
      flex-direction: column;
      width: 25%;
      row-gap: 10px;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .tox-tinymce {
    height: 52vh !important;
  }
}
