.rekvizity-dlya-perechisleniya-container {
  .head-container {
    margin-bottom: 15px;
  }
  .head-text {
    width: 50%;
    max-height: 75px;
    margin-left: auto;
  }
  .body-container {
    display: flex;
    column-gap: 15px;
    flex-direction: row;
    height: 400px;
    .rdw-editor-wrapper {
      overflow-y: auto;
    }
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
  }
  .requisites-container {
    width: 100%;
  }
  .requisite-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
    label {
      width: 35%;
      margin: 0;
      text-align: center;
    }
  }
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .date-input {
    width: 25%;
  }
  .signature {
    width: 30%;
  }
  .additional-container__date {
    width: fit-content;
  }
  .tox-tinymce {
    height: 60vh !important;
  }
}
