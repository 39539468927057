.recall-client-info{
    font-size: .8rem;
    .material-icons{
        font-size: 0.8rem;
        margin-right: .3rem;
    }
    >*:not(.clinet-name){
    font-size: .6rem;
    color: $dark-text-color;
    }
}