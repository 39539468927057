footer {
	background-color: $card-bg-color;
	margin-top: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	.footer-content {
		display: inline-block;
		a {
			color: $text-color;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
