@import "./colors.scss";

/*--- MATERIAL ICONS ---*/
// Doesn't work
// @TODO: Figure out why!!!
$material-icons-font-size: 20px;

$desktop: 1024px;
$tablet: 768px;
$mobile: 375px;

@mixin base-style-dark {
  background-color: $dark--card-bg-color;
  color: $dark--text-color;
}
