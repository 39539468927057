.user-statuses-container {
  position: relative;
  button {
    font-size: 0.8rem;
  }
  input[type="checkbox"] {
    display: none;
  }
  button[disabled] {
    cursor: auto;
  }
  @media (max-width: calc(1024px - 1px)) {
    justify-content: space-around !important;
    flex-wrap: wrap;
    gap: 0.5rem;
    & > * {
      margin: 0 !important;
    }
  }

  @media (max-width: calc(768px - 1px)) {
    justify-content: space-between !important;
  }

  .backdrop {
    display: none;
    &.shown {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200%;
      background-color: hsla(0, 0, 0, 0.6);
      font-size: 1rem;
      white-space: pre-wrap;
    }
  }
}
