.dogovor-kupli-prodagi-avto {
  .additional-container {
    .signature {
      width: 50%;
    }
  }
  .label {
    text-wrap: nowrap;
  }
  h6 {
    margin-bottom: 5px;
  }
  .tox-tinymce {
    height: 700px !important;
    width: 100%;
    max-width: 780px;
  }
}
