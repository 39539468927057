.start-working {
  .buttons-container {
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
    flex-direction: row;
    .button-wrapper {
      padding: 0 6px;
      button {
        white-space: nowrap;
        font-size: 0.8rem;
      }
    }
  }
}
