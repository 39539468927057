.roskomnadzor {
  h6 {
    margin-bottom: 5px;
  }
  .body-container {
    .tox-tinymce {
      height: 800px !important;
    }
  }
}
