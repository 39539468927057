.udostoverenie-container {
  .head-container {
    margin-bottom: 15px;
  }
  .head-text {
    width: 50%;
    max-height: 75px;
    margin-left: auto;
  }
  .body-container {
    display: flex;
    column-gap: 15px;
    flex-direction: row;
    height: 520px;
    .rdw-editor-wrapper {
      overflow-y: auto;
    }
  }
  .left-body-container {
    width: 50%;
    .card {
      height: calc(50% - 1rem);
    }
  }
  .employee-container {
    width: 50%;
    row-gap: 10%;

    .tox-tinymce {
      height: 100% !important;
    }
  }
  .employeeIDNumber-container {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    label {
      width: 50%;
      margin: 0;
    }
  }
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .additional-container {
    width: 100%;
    column-gap: 15px;
    .dg__signature {
      width: 55%;
    }
    .ds__use-signature {
      width: 15%;
    }
    .signature {
      width: 25%;
    }
    .additional-container__date {
      width: fit-content;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
}
