.imported-data-container{
    overflow: auto;
    max-height: 70vh;
}

table.imported-data{
    overflow: auto;
    
    tr{
        border-top: 1px solid $text-color !important;
    }
    thead{
        tr{
            border-top: 0 !important;
        }
    }
    th, td{
        color: $text-color;
        font-weight: 300;
        padding: .8rem;
       
    }

}