.nbk-seyfovaya-yacheyka-kredit {
  .btn {
    margin-bottom: 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
  .header-container {
    .form-control {
      width: 50%;
      margin: 0 auto;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .position-container {
    column-gap: 15rem;
  }
  .position {
    max-height: 5rem;
  }
  .additional-container {
    .signature {
      width: 100%;
      margin-top: auto;
    }
  }
  h6 {
    margin-bottom: 5px;
  }
  .tox-tinymce {
    height: 630px !important;
  }
}
