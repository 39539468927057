.file-selector {
	border-radius: 3px;
	overflow: hidden;
	padding: 0;
	padding-left: 0.75rem;
	display: flex;
	input[type="file"] {
		display: none;
	}
	.select-file-button {
		margin-left: auto;
	}
}
