.db-actions-container {
	button {
		margin-right: 2px;
		color: #ffffff;
		font-size: 14px;
		&:hover {
			color: #ffffff;
		}
	}
	.btn-clear {
		background-color: rgb(150, 98, 31);
		&:hover {
			background-color: rgb(173, 119, 49);
		}
	}
	.btn-define-time {
		background-color: rgb(107, 37, 219);
		&:hover {
			background-color: rgb(124, 65, 218);
		}
	}
	.btn-remove-duplicates {
		background-color: rgb(28, 154, 228);

		&:hover {
			background-color: rgb(51, 162, 226);
		}
	}
}
