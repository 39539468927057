.alert {
  position: fixed;
  right: 24px;
  bottom: 24px;
  cursor: pointer;
  width: auto;
  z-index: 10;
  white-space: pre-line;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
}
